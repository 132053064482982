@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Montserrat", sans-serif;
}

details > summary::-webkit-details-marker {
  display: none;
}
.about-bg {
  background-image: url("/src/Assets/images/world-map.png");
  background-position: center calc(50% + 35%);
  background-size: cover;
  background-repeat: no-repeat;
}
.error-bg {
  background-image: url("/src/Assets/images/404.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.hero-bg {
  /* background-image: url("/src/Assets/images/world-map.png"); */

  /* background-image: url("/src/Assets/images/hero-bg.png"); */
  background-size: cover;
  background-repeat: no-repeat;
}
.products-bg {
  background-image: url("/src/Assets/images/solid-blue-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
}
.cta-bg {
  background: linear-gradient(
      46deg,
      rgba(89, 13, 164, 0.1) 31.94%,
      rgba(184, 154, 234, 0.1) 100%
    ),
    linear-gradient(
      337deg,
      rgba(89, 13, 164, 0.2) 0%,
      rgba(89, 13, 164, 0) 100%
    ),
    rgba(89, 13, 164, 0.2);
}
.landing-gradient {
  background: linear-gradient(
    145deg,
    #590da4 69.99%,
    #00d6cc 70%,
    rgba(136, 192, 44, 0) 70%,
    rgba(136, 192, 44, 0) 100%
  );
}
.text-gradient {
  color: #590da4;
}

.header-gradient {
  background: linear-gradient(145deg, #9076fc 10%, #61cfeb 50%, #9076fc 90%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.rotate-image {
  transform: rotate(-10.041deg) !important;
}

        
.gray-bg{
    background-image: url("/src/Assets/images/gray.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    
}
.datePicker {
  width: 100%;
}