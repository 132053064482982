.footer-text,
.footer-text span {
  font-size: 16px !important;
  color: #000 !important;
}

.copyright {
  color: #a0abbb;
  font-size: 18px;
}

.btn-primary {
  background-color: #590da4;
  color: #fff;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  text-align: center;
}

.navbar-text span {
  color: #0d0f11;
  font-size: 16px !important;
}

@media only screen and (max-width: 568px) {
  .navbar-text * {
    padding-bottom: 10px !important;
    padding-top: 8px;
    padding-left: 10px;
    background-color: #590da4;
    color: whitesmoke !important;
  }

  .upcoming-content h1 {
    font-weight: 600 !important;
    font-size: 30px !important;
    line-height: 40px !important;
    letter-spacing: -0.02em;
    text-align: center !important;
  }

  .upcoming-content p {
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 27px !important;
    color: #0d0f11;
  }

  .hero-header-text h2 {
    font-weight: 800 !important;
    font-size: 35px !important;
    line-height: 45px !important;
  }

  /* .navbar-text *:hover{
  background-color: red;
} */
}

.contact-us h4 {
  font-weight: 700;
  font-size: 24px;
  line-height: 27px;
  color: #323a46;
}

.contact-us p {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #323a46;
}

.contact-info span {
  font-weight: 500;
  font-size: 17px;
  line-height: 28px;
  color: #323a46;
}

.navbar-text span:hover {
  color: #88c02c !important;
}

.navbar-container {
  padding-top: 20px;
  padding-bottom: 15px;
}

.hero-header-text h2 {
  font-weight: 800 !important;
  font-size: 40px;
  line-height: 55px;
}

.hero-header-text span {
  color: #88c02c;
}

.hero-header-text p {
  font-style: normal;
  /* font-weight: 300; */
  font-size: 18px;
  line-height: 27px;
  color: #323a46;
}

.hero-header-text Button {
  background-color: red !important;
  color: red !important;
}

.how-it-works-icon {
  background: #7a3db6;
  box-shadow: 0px 8px 20px rgba(89, 13, 164, 0.25);
  width: 59.92px;
  height: 59.92px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
}

.how-it-works-text {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.target-content h1 {
  font-weight: 900;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #590da4;
}

.target-content p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #323a46;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.upcoming-content h1 {
  font-weight: 800;
  font-size: 42px;
  line-height: 55px;
  letter-spacing: -0.02em;
}

.upcoming-content p {
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #0d0f11;
}

.flex-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.content-wrapper {
  box-shadow: 0px 5px 18px 0px rgba(0, 0, 0, 0.05) inset;
  -webkit-box-shadow: 0px 5px 18px 0px rgba(0, 0, 0, 0.05) inset;
  -moz-box-shadow: 0px 5px 18px 0px rgba(0, 0, 0, 0.05) inset;
  background: #ffffff;
}

.page-title-sub {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
}

.page-title {
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.02em;
}

.our-purpose {
  background: #ffffff;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.05);
  border-radius: 24px;
}

.our-purpose p {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #0d0f11;
}

.our-purpose span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #4b5768;
}

.about-core-values p {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  margin-top: 10px;
}

.about-core-values span {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #4b5768;
}

.staff-card {
  height: 390px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.staff-card-footer {
  background: rgba(0, 0, 0, 0.7);
  /* backdrop-filter: blur(12px); */
  flex: 1;
  color: #fff !important;
}

.staff-card-footer p {
  color: #fff !important;
  font-weight: 900;
  font-size: 20px;
  line-height: 30px;
}

.staff-card-footer span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
}

.job-card {
  border: 1.5px solid #e7eaee;
  border-radius: 16px;
  margin-bottom: 10px;
}

.job-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.job-card-header p {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #590da4;
}

.job-card-header span {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #323a46;
}

.job-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #0d0f11;
}

.Privacy-content {
  text-align: justify;
}

.Privacy-content p {
  margin-bottom: 5px;
}

.Privacy-content h5 {
  margin-bottom: 12px;
  font-weight: bold !important;
  font-size: 20px !important;
  margin-top: 25px;
}

.Privacy-content li {
  list-style-type: disc;
  margin-bottom: 10px;
  margin-left: 20px;
}

.form-input {
  border: 1.5px solid #d0d5dd;
  border-radius: 8px;
}





.file-area .file-dummy {
  width: 100%;
  padding: 15px;
  background: rgba(255, 255, 255, 0.1);
  border: 2px dashed #D0D5DD;
  border-radius: 5px;
  /* text-align: center; */
  transition: background 0.3s ease-in-out;
}

.file-area .file-dummy .success {
  display: none;
}

.file-area:hover .file-dummy {
  background: rgba(255, 255, 255, 0.1);
}

.file-area input[type="file"]:focus + .file-dummy {
  outline: 2px solid rgba(255, 255, 255, 0.5);
  outline: -webkit-focus-ring-color auto 5px;
}

.file-area input[type="file"]:valid + .file-dummy {
  border-color: #6eb4e0;
  background-color: #ebf8fe;
}

.file-area input[type="file"]:valid + .file-dummy .success {
  display: inline-block;
}

.file-area input[type="file"]:valid + .file-dummy .default {
  display: none;
}
